const routesName = {
  login: "login",
  registration: "registration",
  reset: "resetPassword",
  restore: "restorePassword",
  landing: "landing",
  onboarding: "onboarding",
  onboardingStep: "onboardingStep",
  myCourses: "myCourses",
  myCertificates: "myCertificates",
  myProfile: "myProfile",
  mySettings: "mySettings",
  dashboard: "dashboard",
  course: "course",
  courseFollowUp: "courseFollowUp",
  coursesList: "coursesList",
  center: "center",
  module: "module",
  centersList: "centersList",
  faq: "faq",
  contactUs: "contactUs",
  notFound: "notFound",
  profileOnboarding: "profileOnboarding",
  confirmAccount: "confirmAccount",
};

export default routesName;
